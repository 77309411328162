import React, { Suspense, lazy, useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Loader from 'Common/components/Loader';
import PrivateRoute from 'routes/PrivateRoute';
import useQueryApi, { queryKind, saveResults } from 'hooks/useQueryApi';
import { CTX, storeKey } from 'Store';
import session from 'Common/scripts/session';

const NotFound = lazy(() => import('pages/NotFound'));
const Error = lazy(() => import('pages/Error'));
const Account = lazy(() => import('pages/Account'));
const LicenseUsage = lazy(() => import('pages/LicenseUsage'));
const UserDecommissions = lazy(() => import('pages/UserDecommissions'));
// const Company = lazy(() => import('pages/Company'));
const AccountSettings = lazy(() => import('pages/AccountSettings'));
const CurrentUsers = lazy(() => import('pages/CurrentUsers'));

const guidPattern =
    '(/account/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/)';

function Routes() {
    const [appState, doAction] = useContext(CTX);
    const userList = appState?.[storeKey.userList];
    const [tokens, setTokens] = useState(null);

    // This query will return the data without running fetch if data is already saved in the store
    // The Account page is set up to handle error codes from this api call
    const { data, loading, error, errorCode } = useQueryApi(
        queryKind.appSettings,
        null,
        true,
        saveResults
    );

    // get currently logged in user from cookies and save to store
    useEffect(() => {
        if (!tokens) session.getUserData().then(resp => setTokens(resp));
    }, [tokens]);

    useEffect(() => {
        const tokenUser = userList?.UsersInAccount?.find(
            u => u.UserId == tokens?.idToken?.extension_userId
        );

        if (tokenUser) {
            doAction({
                key: storeKey.tokenUser,
                payload: tokenUser
            });
        }
    }, [userList, tokens]);

    return (
        <Router>
            <Suspense fallback={<Loader loading={true} isPageLoader />}>
                <Switch>
                    {/*
                    // * https://github.com/ReactTraining/react-router/issues/820#issuecomment-313794272
                    // * for any route that doesn't have a trailing slash, add it
                    */}
                    <Route
                        path="/:url*"
                        exact
                        strict
                        render={props => <Redirect to={`${props.location.pathname}/`} />}
                    />
                    {/* check for trial users */}
                    {!!data?.AccountType ? (
                        <Redirect exact from="/" to="/account/?trialuser=true" />
                    ) : (
                        <Redirect exact from="/" to="/account/" />
                    )}
                    {/* For requests to /account/<GUID>, redirect to /account/ */}
                    <Redirect exact from={guidPattern} to="/account/" />
                    {/* Account will handle any api failures */}
                    <PrivateRoute
                        path="/account/"
                        component={Account}
                        loading={loading}
                        error={error}
                        errorCode={errorCode}
                        exact
                        strict
                    />
                    <PrivateRoute
                        path="/account/?trialuser=true"
                        component={Account}
                        exact
                        strict
                    />
                    <PrivateRoute path="/licenseusage/licensing">
                        <Redirect to="/licenseusage/" />
                    </PrivateRoute>
                    <PrivateRoute path="/licenseusage/" component={LicenseUsage} exact strict />
                    <PrivateRoute
                        path="/userdecommissions/"
                        component={UserDecommissions}
                        exact
                        strict
                    />
                    {/* <PrivateRoute path="/company/" component={Company} /> */}
                    <PrivateRoute path="/currentusers/" component={CurrentUsers} />
                    <PrivateRoute path="/accountsettings/" component={AccountSettings} />
                    <Route path="/error/" component={Error} exact strict />
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </Router>
    );
}

export default Routes;
