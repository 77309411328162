import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

import {
    faCog,
    faFileAlt,
    faSignOutAlt,
    faCheck,
    faTimes,
    faDownload,
    faArrowLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faLaptop,
    faTimesCircle,
    faSpinner,
    faUserCircle,
    faHome
} from '@fortawesome/free-solid-svg-icons';

const icons = [
    faCog,
    faFileAlt,
    faEyeSlash,
    faEye,
    faSignOutAlt,
    faCheck,
    faTimes,
    faDownload,
    faArrowLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faLaptop,
    faTimesCircle,
    faSpinner,
    faUserCircle,
    faHome
];

const registerIcons = () => {
    icons.forEach(icon => library.add(icon));
};

registerIcons();

export default registerIcons;
