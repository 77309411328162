import { queryKind } from '../useQueryApi';
import domain from 'Common/scripts/domain';

// this returns an object containing path, init and a body when needed
function getFetchRequestData(kind, tokens, values) {
    const dynamicDomain = domain.getDynamicApi(window.env.NODE_ENV);
    const dynamicRegistrationDomain = domain.getDynamicRegistrationApi(window.env.NODE_ENV);
    let opts = {};

    switch (kind) {
        case queryKind.getUsers:
            opts = {
                path: `${dynamicDomain}/accounts/GetUsers`,
                init: {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    }
                }
            };
            break;
        case queryKind.denyUser:
            opts = {
                path: `${dynamicDomain}/users/UpdateUserApprovalStatus`,
                init: {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify({
                        ...values // an object that contains userid
                    })
                }
            };
            break;
        case queryKind.approveUser:
            opts = {
                path: `${dynamicDomain}/users/UpdateUserApprovalStatus`,
                init: {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify({
                        ...values, // an object that contains userid
                        userApprovalStatus: '1'
                    })
                }
            };
            break;
        case queryKind.getSdeLatest:
            opts = {
                path: `${dynamicDomain}/configuration/latest`,
                init: {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            };
            break;
        case queryKind.postSdeDownload:
            opts = {
                path: `${dynamicDomain}/users/download`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: '{}' // can be an object that contains `Version: '<Previous Version>'`
                }
            };
            break;
        case queryKind.appSettings:
            opts = {
                path: `${dynamicDomain}/accounts/${tokens?.idToken?.extension_accountId}/appSettings`,
                init: {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    }
                }
            };
            break;
        case queryKind.deployments:
            opts = {
                path: `${dynamicDomain}/accounts/app/${tokens?.idToken?.extension_accountId}/deployments`,
                init: {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    }
                }
            };
            break;
        case queryKind.decommission:
            opts = {
                path: `${dynamicDomain}/accounts/${tokens?.idToken?.extension_accountId}/decommissionDeployments`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify(
                        { MachinesDeployedIds: values } // an array that contains the MachinesDeployedId strings
                    )
                }
            };
            break;
        case queryKind.refresh:
            opts = {
                path: `${dynamicRegistrationDomain}/users/refresh`, // this call needs to go to a different domain than the others
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify({ RefreshToken: tokens.refreshToken })
                }
            };
            break;
        case queryKind.getUserDetails:
            opts = {
                path: `${dynamicDomain}/users/userDetails/${tokens.idToken.extension_userId}`,
                init: {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    }
                }
            };
            break;
        case queryKind.updateUserDetails:
            opts = {
                path: `${dynamicDomain}/users/updateUserDetails`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify({
                        UserId: tokens.idToken.extension_userId,
                        ...values // an object that contains firstName, lastName, Telephone, Title
                    })
                }
            };
            break;
        case queryKind.updateAccountDetails:
            opts = {
                path: `${dynamicDomain}/account/updateAccountDetails`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify({
                        UserId: tokens.idToken.extension_userId,
                        ...values
                    })
                }
            };
            break;
        case queryKind.updateUserRole:
            opts = {
                path: `${dynamicDomain}/users/updateUserRole`,
                init: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${tokens.accessToken}`
                    },
                    body: JSON.stringify({
                        ...values
                    })
                }
            };
    }

    if (window.env.API_SUB_KEY && opts.init?.headers) {
        opts.init.headers['Ocp-Apim-Subscription-Key'] = window.env.API_SUB_KEY;
    }

    return opts;
    // prettier-ignore
}

export default getFetchRequestData;
