import React, { useState, useEffect, useContext } from 'react';
import { CTX, storeKey } from 'Store';
import ConnectionError from 'Common/components/ConnectionError';
import getStoreKey from './scripts/get-store-key';
import fetchData from './scripts/fetch-data';
import formattedDeployments from 'scripts/formattedDeployments';

// * The component calling this hook is responsible for
// 1. Adding the data to the store if needed via the saveToStateCallback function
// 2. Displaying errors as needed
// 3. Ensuring that the data it recieved is what was needed
//   i.e. if the api responed with 200 and returned an empty object
//   the component may still need to display a notification for the user

// * Reference: https://www.robinwieruch.de/react-hooks-fetch-data

// just a list of strings - safer & eaiser to keep names consistant this way vs just writing in strings everywhere
const queryKind = {
    getUsers: 'getUsers',
    denyUser: 'denyUser',
    approveUser: 'approveUser',
    getSdeLatest: 'getSdeLatest',
    appSettings: 'appSettings',
    deployments: 'deployments',
    postSdeDownload: 'postSdeDownload',
    decommission: 'decommission',
    refresh: 'refresh',
    getUserDetails: 'getUserDetails',
    updateUserDetails: 'updateUserDetails',
    updateAccountDetails: 'updateAccountDetails',
    updateUserRole: 'updateUserRole'
};

const saveResults = (data, key, doAction) => {
    if (data) {
        if (key === storeKey.deployments) {
            data.Deployments = formattedDeployments(data.Deployments);
        }
        doAction({ key: key, payload: data });
    }
};

function useQueryApi(kind, requestBody = null, isReady = true, saveResultsCallback = () => {}) {
    const [appState, doAction] = useContext(CTX);
    // set to null instead of false because sometimes components need to know if the values have been set yet or not
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    // get the storeKey based on the kind of query we're calling
    const key = getStoreKey(kind);
    // if the data's in the store, return it. useEffect's fetch request won't run
    const [data, setData] = useState(() => {
        // console.log('checking for', kind, 'in the store');
        if (!isReady) return null;
        return appState?.[key] || null;
    });

    useEffect(() => {
        let isSubscribed = true;

        // if the component calling useQueryApi isn't ready yet, don't fetch
        if (!isReady) return undefined;

        // if we already have data, don't fetch - the existing data will be returned from the appState
        if (data) return undefined;

        const fetching = async () => {
            try {
                // console.log('fetching', kind);

                if (isSubscribed) setLoading(true);

                // this runs if the fetch response was not ok
                const handleFetchFailure = (response, responseData) => {
                    // set an error for the parent component to use
                    if (isSubscribed) {
                        setError(`${responseData.Description}`);
                        setErrorCode(`${responseData.ErrorCode}`);
                    }
                    throw Error(
                        `Connection to the SmartDeploy API failed ${response.status} (${response.statusText})`
                    );
                };

                // fetch the requested data
                const responseData = await fetchData(kind, requestBody, handleFetchFailure);

                // save and set data
                if (responseData && isSubscribed) {
                    // saves data to the global Store if needed
                    saveResultsCallback(responseData, key, doAction);
                    setData(responseData);
                }
            } catch (err) {
                // if the !response.ok check did NOT setError & throw the Error
                // then display the default error message
                if (isSubscribed && !err.message.startsWith('Connection')) {
                    setError(<ConnectionError />);
                }
                console.error(err);
            } finally {
                if (isSubscribed) setLoading(false);
            }
        };

        fetching();

        // cleanup when the component is unmountend
        return () => {
            isSubscribed = false;
            setData(null);
            setLoading(null);
            setError(null);
            setErrorCode(null);
        };
    }, [isReady]);

    return { data, loading, error, errorCode };
}

export { useQueryApi as default, saveResults, queryKind };
