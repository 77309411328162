import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

const CTX = createContext();

// we have separate values for the modal active state and the modal id because otherwise
// the modal content vanishes before the modal is visually off screen
const storeKey = {
    userList: 'userList',
    deployments: 'DeploymentData', // pascal case becuase that's how it is in the api
    modalIsActive: 'modalIsActive',
    permissionsModalIsActive: 'permissionsModalIsActive',
    permissionsUpdating: 'permissionsUpdating',
    modalUserId: 'modalUserId',
    appSettings: 'appSettings',
    tokenUser: 'tokenUser',
    userDetails: 'userDetails'
};

// see The Reducer in the wiki for more info
const reducer = (state, action) => ({
    ...state,
    [action.key]: action.payload
});

// use this for easier debugging
// const reducer = (state, action) => {
//     if (action.key === 'userList') {
//         console.log('action key: ', action.key, 'action payload: ', action.payload);
//     }

//     return {
//         ...state,
//         [action.key]: action.payload
//     };
// };

const Store = ({ children }) => {
    const stateHook = useReducer(reducer, null);
    return <CTX.Provider value={stateHook}>{children}</CTX.Provider>;
};

Store.propTypes = {
    children: PropTypes.node.isRequired
};

export { Store as default, storeKey, CTX };
