import { storeKey } from 'Store';

// * storeKey = an object key from /src/Store.jsx
// * it translates between useQueryAPi's kind and the values in the store

// * not every api call will have a store key as not all data is saved to the store

function getStoreKey(kind) {
    switch (kind) {
        case 'getUsers':
            return storeKey.userList
        case 'appSettings':
            return storeKey.appSettings
        case 'deployments':
                return storeKey.deployments
        case 'getUserDetails':
            return storeKey.userDetails
        // updateUserDetails returns the same data as getUserDetails
        // so we overwrite the existing value instead of setting a new one
        case 'updateUserDetails':
                return storeKey.userDetails
        default:
            return kind;
    }
    // prettier-ignore
}

export default getStoreKey;
