import React from 'react';
import smartFocusRing from 'smart-focus-ring';
import Routes from 'Routes';
import Store from 'Store';
import 'scripts/fontAwesome';
import 'styles/global-styles.scss';

smartFocusRing();

export default function App() {
    return (
        <Store>
            <Routes />
        </Store>
    );
}
