const deployStatusToString = status => {
    switch (status) {
        case 0:
            return 'Active';
        case 1:
            return 'Pending';
        case 2:
            return 'Decommissioned';
        case 3:
            return 'Recommissioned';
        case 4:
            return 'Deleted';
        default:
            return null;
    }
};

const formattedDeployments = deployments => {
    return deployments.map(item => {
        item.MachineDeployedStatus = deployStatusToString(item.MachineDeployedStatus);
        return item;
    });
};

export default formattedDeployments;
