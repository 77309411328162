import session from 'Common/scripts/session';
import getFetchRequestData from './get-fetch-request-data';
import isRefreshTokenValid from './is-refresh-token-valid';
import { queryKind } from '../useQueryApi';
const jwt = require('jsonwebtoken');

// * first we'll check for a valid refresh token, then we'll get the data that was requested

const fetchRefresh = async handleFetchFailure => {
    const tokens = await session.getUserData();

    // check the refresh token
    let refreshTokenIsValid = isRefreshTokenValid(tokens);

    // if it's invalid then we make an api call to get a new one
    if (!refreshTokenIsValid) {
        const { path, init } = getFetchRequestData(queryKind.refresh, tokens);
        const response = await fetch(`${path}`, init);
        const responseData = await response.json();

        if (!response.ok) {
            // If a refresh fails then take the user back to sign in.
            window.location = window.env.REG_DOMAIN + '/signin';
        } else {
            // save the new token to the cookie
            // should this have an 'await' as well?
            session.storeUserData(
                {
                    accessToken: responseData.AccessToken,
                    refreshToken: responseData.RefreshToken,
                    idToken: jwt.decode(responseData.IdToken)
                },
                true
            );
        }
        // } else {
        //     console.log('valid');
    }
};

const fetchDataKind = async (kind, requestBody, handleFetchFailure) => {
    const tokens = await session.getUserData();
    const { path, init } = getFetchRequestData(kind, tokens, requestBody);
    const response = await fetch(`${path}`, init);
    const responseData = await response.json();

    if (!response.ok) {
        handleFetchFailure(response, responseData);
        return false;
    } else {
        return responseData;
    }
};

const fetchData = (queryKind, requestBody, handleFetchFailure) => {
    // check and/or fetch the refresh token, then get the requested data
    return fetchRefresh().then(() => {
        return fetchDataKind(queryKind, requestBody, handleFetchFailure);
    });
};

export default fetchData;
