import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import session from 'Common/scripts/session';

export default function PrivateRoute(props) {
    const { component: Component, ...rest } = props;
    const [loading, setLoading] = useState(true);
    const [signedIn, setSignedIn] = useState(false);

    useEffect(() => {
        const getSession = async () => {
            const tokens = await session.getUserData();
            if (tokens?.idToken) {
                setSignedIn(true);
                setLoading(false);
            } else {
                window.location = window.env.REG_DOMAIN + '/signin';
            }
        };
        getSession();
    });

    return (
        // prettier-ignore
        <Route {...rest} render={props => {
            if(signedIn) {
                return <Component {...props} />
            }
            if(!signedIn && !loading) {
                return <Redirect to={{ pathname: '/error' }} />
            }
            // rendering null keeps us from going to a route until useEffect is finished
            return null;
        }
        }/>
    );
}
